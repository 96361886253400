import React from "react"
import Layout from "../components/layout"
import Section from "../components/section"

const Imprint = () => (
  <Layout>
    <Section>
      <div className="impressum">
        <h1>Impressum</h1>
        <p>Angaben gemäß § 5 TMG</p>
        <p>
          Christina Röttgers <br />
          Gilbachstr. 27
          <br />
          50672 Köln <br />
        </p>
        <p>
          {" "}
          <strong>Vertreten durch: </strong>
          <br />
          Christina Röttgers
          <br />
        </p>
        <p>
          <strong>Kontakt:</strong> <br />
          Telefon: 0173-4771170
          <br />
          E-Mail: <a href="mailto:contact@christinaroettgers.com">contact@christinaroettgers.com</a>
          <br />
        </p>
        <p>
          <strong>Umsatzsteuer-ID: </strong> <br />
          Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:
          DE215668757
          <br />
        </p>
        <p>
          <strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong>
          <br />
          Christina Röttgers <br />
          Gilbachstr. 27
          <br />
          50672 Köln
        </p>
        Website Impressum von{" "}
        <a href="https://www.impressum-generator.de">impressum-generator.de</a>
      </div>
    </Section>
  </Layout>
)

export default Imprint
